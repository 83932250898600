export const AuthMenu = () => import('../../components/nav/AuthMenu.vue' /* webpackChunkName: "components/auth-menu" */).then(c => wrapFunctional(c.default || c))
export const ColorModeSwitcher = () => import('../../components/nav/ColorModeSwitcher.vue' /* webpackChunkName: "components/color-mode-switcher" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/nav/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelector = () => import('../../components/nav/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c))
export const Options = () => import('../../components/nav/Options.vue' /* webpackChunkName: "components/options" */).then(c => wrapFunctional(c.default || c))
export const PageNav = () => import('../../components/nav/PageNav.vue' /* webpackChunkName: "components/page-nav" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/nav/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const ButtonToggle = () => import('../../components/controls/ButtonToggle.vue' /* webpackChunkName: "components/button-toggle" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/controls/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/controls/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const Bar = () => import('../../components/Bar.vue' /* webpackChunkName: "components/bar" */).then(c => wrapFunctional(c.default || c))
export const ButtonMenu = () => import('../../components/ButtonMenu.vue' /* webpackChunkName: "components/button-menu" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../components/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const DevWarning = () => import('../../components/DevWarning.vue' /* webpackChunkName: "components/dev-warning" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SectionAccountInfoBanner = () => import('../../components/SectionAccountInfoBanner.vue' /* webpackChunkName: "components/section-account-info-banner" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const AccountMenu = () => import('../../components/account/AccountMenu.vue' /* webpackChunkName: "components/account-menu" */).then(c => wrapFunctional(c.default || c))
export const AccountSidebar = () => import('../../components/account/AccountSidebar.vue' /* webpackChunkName: "components/account-sidebar" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/list/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const ListFilter = () => import('../../components/list/ListFilter.vue' /* webpackChunkName: "components/list-filter" */).then(c => wrapFunctional(c.default || c))
export const ListItem = () => import('../../components/list/ListItem.vue' /* webpackChunkName: "components/list-item" */).then(c => wrapFunctional(c.default || c))
export const OrganisationsOrganisationList = () => import('../../components/organisations/OrganisationList.vue' /* webpackChunkName: "components/organisations-organisation-list" */).then(c => wrapFunctional(c.default || c))
export const OrganisationsOrganisationListFilter = () => import('../../components/organisations/OrganisationListFilter.vue' /* webpackChunkName: "components/organisations-organisation-list-filter" */).then(c => wrapFunctional(c.default || c))
export const OrganisationsOrganisationListItem = () => import('../../components/organisations/OrganisationListItem.vue' /* webpackChunkName: "components/organisations-organisation-list-item" */).then(c => wrapFunctional(c.default || c))
export const OrganisationsOrganisationProfileSidebar = () => import('../../components/organisations/OrganisationProfileSidebar.vue' /* webpackChunkName: "components/organisations-organisation-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const OrganisationsOrganisationTopicMenu = () => import('../../components/organisations/OrganisationTopicMenu.vue' /* webpackChunkName: "components/organisations-organisation-topic-menu" */).then(c => wrapFunctional(c.default || c))
export const ParliamentarianList = () => import('../../components/parliamentarian/ParliamentarianList.vue' /* webpackChunkName: "components/parliamentarian-list" */).then(c => wrapFunctional(c.default || c))
export const ParliamentarianListFilter = () => import('../../components/parliamentarian/ParliamentarianListFilter.vue' /* webpackChunkName: "components/parliamentarian-list-filter" */).then(c => wrapFunctional(c.default || c))
export const ParliamentarianListItem = () => import('../../components/parliamentarian/ParliamentarianListItem.vue' /* webpackChunkName: "components/parliamentarian-list-item" */).then(c => wrapFunctional(c.default || c))
export const ParliamentarianProfileSidebar = () => import('../../components/parliamentarian/ParliamentarianProfileSidebar.vue' /* webpackChunkName: "components/parliamentarian-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PreferencesBill = () => import('../../components/preferences/Bill.vue' /* webpackChunkName: "components/preferences-bill" */).then(c => wrapFunctional(c.default || c))
export const PreferencesPreferenceResult = () => import('../../components/preferences/PreferenceResult.vue' /* webpackChunkName: "components/preferences-preference-result" */).then(c => wrapFunctional(c.default || c))
export const PreferencesList = () => import('../../components/preferences/PreferencesList.vue' /* webpackChunkName: "components/preferences-list" */).then(c => wrapFunctional(c.default || c))
export const PreferencesRate = () => import('../../components/preferences/Rate.vue' /* webpackChunkName: "components/preferences-rate" */).then(c => wrapFunctional(c.default || c))
export const PreferencesVote = () => import('../../components/preferences/Vote.vue' /* webpackChunkName: "components/preferences-vote" */).then(c => wrapFunctional(c.default || c))
export const RankingList = () => import('../../components/ranking/RankingList.vue' /* webpackChunkName: "components/ranking-list" */).then(c => wrapFunctional(c.default || c))
export const RankingListFilter = () => import('../../components/ranking/RankingListFilter.vue' /* webpackChunkName: "components/ranking-list-filter" */).then(c => wrapFunctional(c.default || c))
export const RankingListItem = () => import('../../components/ranking/RankingListItem.vue' /* webpackChunkName: "components/ranking-list-item" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/search/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/search/SearchResult.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
