export const state = () => ({
	rankings: {},
	lpVotesCount: null,
});

export const mutations = {
	ADD_RANKING(state, { organisationId, totalPreferences, ranking }) {
		if (!(organisationId in state.rankings))
			state.rankings[organisationId] = {
				totalPreferences,
				ranking,
			};
	},
	REMOVE_RANKING(state, organisationId) {
		if (organisationId in state.rankings) delete state.rankings[organisationId];
	},
	RESET_RANKINGS(state) {
		state.rankings = {};
	},
	SET_LP_VOTES_COUNT(state, res) {
		state.lpVotesCount = res;
	},
	RESET_LP_VOTES_COUNT(state) {
		state.lpVotesCount = null;
	},
};

export const getters = {
	getRanking: (state) => (organisationId) => {
		return state.rankings[organisationId];
	},
	getRankingDescOrg: (state, getters, rootState) => (ranking, locale) => {
		const percentage = Math.round((ranking.totalPreferences / state.lpVotesCount) * 10000) / 100;
		switch (locale) {
			case `en`:
				return `${ranking.totalPreferences} von ${state.lpVotesCount} Abstimmungen (${percentage}%) in der ${rootState.lp}. Legislaturperiode bewertet.`;
			case `de`:
				return `${ranking.totalPreferences} von ${state.lpVotesCount} Abstimmungen (${percentage}%) in der ${rootState.lp}. Legislaturperiode bewertet.`;
			case `fr`:
				return `${ranking.totalPreferences} von ${state.lpVotesCount} Abstimmungen (${percentage}%) in der ${rootState.lp}. Legislaturperiode bewertet.`;
			case `it`:
				return `${ranking.totalPreferences} von ${state.lpVotesCount} Abstimmungen (${percentage}%) in der ${rootState.lp}. Legislaturperiode bewertet.`;
		}
	},
	getRankingDescOrgParliamentarian:
		(state, getters, rootState) => (ranking, parliamentarianName, organisationName, locale) => {
			const percentage = Math.round((ranking.participationCount / state.lpVotesCount) * 10000) / 100;
			switch (locale) {
				case `en`:
					return `${ranking.score} out of ${ranking.participationCount} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) of ${parliamentarianName} align with the preference of ${organisationName}.`;
				case `de`:
					return `${ranking.score} out of ${ranking.participationCount} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) of ${parliamentarianName} align with the preference of ${organisationName}.`;
				case `fr`:
					return `${ranking.score} out of ${ranking.participationCount} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) of ${parliamentarianName} align with the preference of ${organisationName}.`;
				case `it`:
					return `${ranking.score} out of ${ranking.participationCount} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) of ${parliamentarianName} align with the preference of ${organisationName}.`;
			}
		},
	getRankingDescParliamentarian: (state, getters, rootState) => (parliamentarian, organisation, locale) => {
		const percentage = Number((parliamentarian.participated / state.lpVotesCount) * 100).toFixed(2);
		// ${score} out of ${participated} votings (${votingsParticipationPercentage} out of ${votingsAll} of the ${lp} legislative period) align with the preference of ${organisationName}.
		switch (locale) {
			case `en`:
				return `${parliamentarian.score} out of ${parliamentarian.participated} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `de`:
				return `${parliamentarian.score} out of ${parliamentarian.participated} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `fr`:
				return `${parliamentarian.score} out of ${parliamentarian.participated} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `it`:
				return `${parliamentarian.score} out of ${parliamentarian.participated} votings (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
		}
	},
	getRankingDescParty: (state, getters, rootState) => (party, organisation, locale) => {
		const percentage = Number((party.participated / party.count / state.lpVotesCount) * 100).toFixed(2);
		// ${score} out of ${participated} votings (${votingsParticipationPercentage} out of ${votingsAll} of the ${lp} legislative period) align with the preference of ${organisationName}.
		switch (locale) {
			case `en`:
				return `${party.score} out of ${party.participated} votings of ${party.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `de`:
				return `${party.score} out of ${party.participated} votings of ${party.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `fr`:
				return `${party.score} out of ${party.participated} votings of ${party.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `it`:
				return `${party.score} out of ${party.participated} votings of ${party.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
		}
	},
	getRankingDescFaction: (state, getters, rootState) => (faction, organisation, locale) => {
		const percentage = Number((faction.participated / faction.count / state.lpVotesCount) * 100).toFixed(2);
		// ${score} out of ${participated} votings (${votingsParticipationPercentage} out of ${votingsAll} of the ${lp} legislative period) align with the preference of ${organisationName}.
		switch (locale) {
			case `en`:
				return `${faction.score} out of ${faction.participated} votings of ${faction.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `de`:
				return `${faction.score} out of ${faction.participated} votings of ${faction.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `fr`:
				return `${faction.score} out of ${faction.participated} votings of ${faction.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
			case `it`:
				return `${faction.score} out of ${faction.participated} votings of ${faction.count} parliamentarians (${percentage}% out of ${state.lpVotesCount} of the ${rootState.lp} legislative period) align with the preference of ${organisation.name}.`;
		}
	},
};

export const actions = {
	async fetchLpVotesCount({ state, commit, rootState }) {
		if (!state.lpVotesCount) {
			const res = await this.$axios.$get(`/api/v1/vote?os=0&ps=0&lp=${rootState.lp}&lang=${this.$i18n.locale}`);
			commit("SET_LP_VOTES_COUNT", res.totalEntities);
		}
	},
	async fetchRanking({ state, commit, dispatch, getters, rootState }, organisationId) {
		if (!getters.getRanking(organisationId)) {
			const res = await this.$axios.$get(`/api/v1/ranking/${organisationId}?lp=${rootState.lp}`);
			const totalPreferences = res.totalPreferences;
			const ranking = {
				parliamentarians: [],
				parties: [],
				factions: [],
			};
			/*
			// Ranking-Structure
			ranking: {
				parliamentarians: [
					{
					  "id": 806,
					  "firstName": "Maya",
					  "lastName": "Graf",
					  "canton": "BL",
					  "gender": "female",
					  "party": 20,
					  "dateOfBirth": "1962-02-28",
					  "score": 6,
					  "participated": 7,
					  "percentage": 85.7143
					},
					...
				],
				"parties": [
					{
					  "id": 16,
					  "count": 1,
					  "score": 657,
					  "participated": 2707,
					  "percentage": 19.4711,
					  "cantons": {
						"BS": {
						  "count": 1,
						  "score": 657,
						  "participated": 2707
						},
						...
					  }
					},
					...
				],
				"factions": [
					{
					  "id": 16,
					  "count": 1,
					  "score": 657,
					  "participated": 2707,
					  "percentage": 19.4711,
					  "cantons": {
						"BS": {
						  "count": 1,
						  "score": 657,
						  "participated": 2707
						},
						...
					  }
					},
					...
				]
			}
			 */
			if (totalPreferences > 0) {
				for (const [key, rankingEntity] of Object.entries(res.ranking)) {
					const parliamentarian = rootState.parliamentarians.find((e) => parseInt(key) === e.id);
					if (parliamentarian) {
						// parliamentarians
						ranking.parliamentarians.push({
							...parliamentarian,
							...rankingEntity,
							percentage:
								Math.round(
									((rankingEntity.score / rankingEntity.participated) * 100 + Number.EPSILON) * 10000
								) / 10000,
						});

						// parties
						const partyIndex = ranking.parties.findIndex((party) => party.id === parliamentarian.party);
						if (partyIndex !== -1) {
							// party is in the array
							ranking.parties[partyIndex].count++;
							ranking.parties[partyIndex].score += rankingEntity.score;
							ranking.parties[partyIndex].participated += rankingEntity.participated;
							if (parliamentarian.canton in ranking.parties[partyIndex].cantons) {
								// canton is in the party
								ranking.parties[partyIndex].cantons[parliamentarian.canton].count++;
								ranking.parties[partyIndex].cantons[parliamentarian.canton].score +=
									rankingEntity.score;
								ranking.parties[partyIndex].cantons[parliamentarian.canton].participated +=
									rankingEntity.participated;
							} else {
								// canton is not in the party yet
								ranking.parties[partyIndex].cantons[parliamentarian.canton] = {
									count: 1,
									...rankingEntity,
								};
							}
						} else {
							// party is not in the array yet
							ranking.parties.push({
								id: parliamentarian.party,
								count: 1,
								...rankingEntity,
								cantons: {
									[parliamentarian.canton]: {
										count: 1,
										...rankingEntity,
									},
								},
							});
						}

						// factions
						const factionIndex = ranking.factions.findIndex(
							(faction) => faction.id === parliamentarian.faction
						);
						if (factionIndex !== -1) {
							// party is in the array
							ranking.factions[factionIndex].count++;
							ranking.factions[factionIndex].score += rankingEntity.score;
							ranking.factions[factionIndex].participated += rankingEntity.participated;
							if (parliamentarian.canton in ranking.factions[factionIndex].cantons) {
								// canton is in the faction
								ranking.factions[factionIndex].cantons[parliamentarian.canton].count++;
								ranking.factions[factionIndex].cantons[parliamentarian.canton].score +=
									rankingEntity.score;
								ranking.factions[factionIndex].cantons[parliamentarian.canton].participated +=
									rankingEntity.participated;
							} else {
								// canton is not in the party yet
								ranking.factions[factionIndex].cantons[parliamentarian.canton] = {
									count: 1,
									...rankingEntity,
								};
							}
						} else {
							// party is not in the array yet
							ranking.factions.push({
								id: parliamentarian.faction,
								count: 1,
								...rankingEntity,
								cantons: {
									[parliamentarian.canton]: {
										count: 1,
										...rankingEntity,
									},
								},
							});
						}
					} else {
						console.log(`parliamentarian ${key} is missing`);
					}
				}
				ranking.parties.forEach((party) => {
					party["percentage"] =
						Math.round(((party.score / party.participated) * 100 + Number.EPSILON) * 10000) / 10000;
				});
				ranking.factions.forEach((faction) => {
					faction["percentage"] =
						Math.round(((faction.score / faction.participated) * 100 + Number.EPSILON) * 10000) / 10000;
				});

				for (const property in ranking) {
					ranking[property].sort((a, b) => b.percentage - a.percentage);
				}
			}

			commit("ADD_RANKING", {
				organisationId,
				totalPreferences,
				ranking,
			});
		}
	},
};
