//
//
//
//
//
//

export default {
	name: 'logo',
}
