//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "color-mode-switcher",
	data() {
		return {
			colorModes: [
				{ name: "light", icon: "sun" },
				{ name: "dark", icon: "moon" },
			],
		};
	},
};
