import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aa3855f2 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _8a87f8ec = () => interopDefault(import('../pages/account/admin/index.vue' /* webpackChunkName: "pages/account/admin/index" */))
const _6bef8b6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _6e771fca = () => interopDefault(import('../pages/account/rate/index.vue' /* webpackChunkName: "pages/account/rate/index" */))
const _7aeffd4a = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _0b09f520 = () => interopDefault(import('../pages/account/preferences.vue' /* webpackChunkName: "pages/account/preferences" */))
const _71e4625a = () => interopDefault(import('../pages/account/rate/_slug.vue' /* webpackChunkName: "pages/account/rate/_slug" */))
const _1553c436 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _26a34926 = () => interopDefault(import('../pages/imprint/index.vue' /* webpackChunkName: "pages/imprint/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _edc5758c = () => interopDefault(import('../pages/method/index.vue' /* webpackChunkName: "pages/method/index" */))
const _5cc8c6e9 = () => interopDefault(import('../pages/mission/index.vue' /* webpackChunkName: "pages/mission/index" */))
const _7ab76a76 = () => interopDefault(import('../pages/organisations/index.vue' /* webpackChunkName: "pages/organisations/index" */))
const _5b8f0c61 = () => interopDefault(import('../pages/parliamentarian/index.vue' /* webpackChunkName: "pages/parliamentarian/index" */))
const _34992776 = () => interopDefault(import('../pages/mission/_.vue' /* webpackChunkName: "pages/mission/_" */))
const _7900c92e = () => interopDefault(import('../pages/organisations/_slug.vue' /* webpackChunkName: "pages/organisations/_slug" */))
const _12b13531 = () => interopDefault(import('../pages/organisations/_slug/index.vue' /* webpackChunkName: "pages/organisations/_slug/index" */))
const _c9658670 = () => interopDefault(import('../pages/organisations/_slug/factions.vue' /* webpackChunkName: "pages/organisations/_slug/factions" */))
const _5705089f = () => interopDefault(import('../pages/organisations/_slug/info.vue' /* webpackChunkName: "pages/organisations/_slug/info" */))
const _4bc157a3 = () => interopDefault(import('../pages/organisations/_slug/parties.vue' /* webpackChunkName: "pages/organisations/_slug/parties" */))
const _928011d2 = () => interopDefault(import('../pages/organisations/_slug/preferences.vue' /* webpackChunkName: "pages/organisations/_slug/preferences" */))
const _4023f7b6 = () => interopDefault(import('../pages/organisations/_slug/_parliamentarian.vue' /* webpackChunkName: "pages/organisations/_slug/_parliamentarian" */))
const _59d86b19 = () => interopDefault(import('../pages/parliamentarian/_slug.vue' /* webpackChunkName: "pages/parliamentarian/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _98173ca8,
    name: "index___de"
  }, {
    path: "/de/account",
    component: _aa3855f2,
    children: [{
      path: "admin",
      component: _8a87f8ec,
      name: "account-admin___de"
    }, {
      path: "/de/account",
      component: _6bef8b6c,
      name: "account___de"
    }, {
      path: "/de/account/bewerten",
      component: _6e771fca,
      name: "account-rate___de"
    }, {
      path: "/de/account/einstellungen",
      component: _7aeffd4a,
      name: "account-settings___de"
    }, {
      path: "/de/account/praeferenzen",
      component: _0b09f520,
      name: "account-preferences___de"
    }, {
      path: "/de/account/bewerten/:slug",
      component: _71e4625a,
      name: "account-rate-slug___de"
    }]
  }, {
    path: "/de/datenschutz",
    component: _1553c436,
    name: "privacy___de"
  }, {
    path: "/de/impressum",
    component: _26a34926,
    name: "imprint___de"
  }, {
    path: "/de/login",
    component: _7d9c04c3,
    name: "login___de"
  }, {
    path: "/de/methode",
    component: _edc5758c,
    name: "method___de"
  }, {
    path: "/de/mission",
    component: _5cc8c6e9,
    name: "mission___de"
  }, {
    path: "/de/organisationen",
    component: _7ab76a76,
    name: "organisations___de"
  }, {
    path: "/de/parlamentarier_innen",
    component: _5b8f0c61,
    name: "parliamentarian___de"
  }, {
    path: "/de/mission/:slug",
    component: _34992776,
    name: "mission-all___de"
  }, {
    path: "/de/organisationen/:slug",
    component: _7900c92e,
    children: [{
      path: "/de/organisationen/:slug",
      component: _12b13531,
      name: "organisations-slug___de"
    }, {
      path: "/de/organisationen/:slug/fraktionen",
      component: _c9658670,
      name: "organisations-slug-factions___de"
    }, {
      path: "/de/organisationen/:slug/information",
      component: _5705089f,
      name: "organisations-slug-info___de"
    }, {
      path: "/de/organisationen/:slug/parteien",
      component: _4bc157a3,
      name: "organisations-slug-parties___de"
    }, {
      path: "/de/organisationen/:slug/praeferenzen",
      component: _928011d2,
      name: "organisations-slug-preferences___de"
    }, {
      path: "/de/organisationen/:slug/:parliamentarian",
      component: _4023f7b6,
      name: "organisations-slug-parliamentarian___de"
    }]
  }, {
    path: "/de/parlamentarier_innen/:slug",
    component: _59d86b19,
    name: "parliamentarian-slug___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
