//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "AuthMenu",
	methods: {
		async logOut() {
			await this.$store.dispatch(`auth/logout`);
		},
	},
};
