//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'language-selector',
	data() {
		return {
			locales: ['de', 'en', 'fr', 'it']
		}
	}
}
