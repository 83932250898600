export const state = () => ({
	cantons: [
		"AG",
		"AI",
		"AR",
		"BE",
		"BL",
		"BS",
		"FR",
		"GE",
		"GL",
		"GR",
		"JU",
		"LU",
		"NE",
		"NW",
		"OW",
		"SG",
		"SH",
		"SO",
		"SZ",
		"TI",
		"TG",
		"UR",
		"VD",
		"VS",
		"ZG",
		"ZH",
	],
	init: false,
	parliamentarians: null,
	parliamentariansDetails: {},
	parliamentariansDecisions: {},
	parties: null,
	factions: null,
	lp: 51,
	orderByDesc: {
		organisations: true,
		ranking: true,
		parliamentarians: true,
	},
	compactView: false,
	parliamentarianGridView: true,
});

export const mutations = {
	SET_PARLIAMENTARIANS(state, res) {
		state.parliamentarians = res;
	},
	ADD_PARLIAMENTARIAN_DETAILED(state, { parliamentarianId, parliamentarianDetails }) {
		state.parliamentariansDetails[parliamentarianId] = parliamentarianDetails;
	},
	RESET_PARLIAMENTARIANS_DETAILED(state) {
		state.parliamentariansDetails = {};
	},
	ADD_PARLIAMENTARIAN_DECISIONS(state, { parliamentarianId, parliamentarianDecisions }) {
		state.parliamentariansDecisions[parliamentarianId] = parliamentarianDecisions;
	},
	RESET_PARLIAMENTARIANS_DECISIONS(state) {
		state.parliamentariansDecisions = {};
	},
	SET_PARTIES(state, res) {
		state.parties = res;
	},
	SET_FACTIONS(state, res) {
		state.factions = res;
	},
	SET_LP(state, res) {
		state.lp = res;
	},
	SET_INIT(state, res) {
		state.init = res;
	},
	SET_RANKING_ORDER_BY_DESC(state, res) {
		if (state.orderByDesc.ranking !== res) {
			state.orderByDesc.ranking = res;
		}
	},
	SET_ORGANISATIONS_ORDER_BY_DESC(state, res) {
		if (state.orderByDesc.organisations !== res) {
			state.orderByDesc.organisations = res;
		}
	},
	SET_PARLIAMENTARIANS_ORDER_BY_DESC(state, res) {
		if (state.orderByDesc.parliamentarians !== res) {
			state.parliamentarians.reverse();
			state.orderByDesc.parliamentarians = res;
		}
	},
	TOGGLE_COMPACT_VIEW(state) {
		state.compactView = !state.compactView;
	},
	TOGGLE_PARLIAMENTARIAN_GRID_VIEW(state) {
		state.parliamentarianGridView = !state.parliamentarianGridView;
	},
};

export const getters = {
	getPartyAbbreviation: (state) => (res) => {
		const partyAbbreviation = state.parties.find((e) => e.id === res);
		return partyAbbreviation.abbreviation || ``;
	},
	getPartyName: (state) => (res) => {
		const partyName = state.parties.find((e) => e.id === res);
		return partyName.name || ``;
	},
	getFactionName: (state) => (res) => {
		const faction = state.factions.find((e) => e.id === res);
		// return faction ? faction.name : this.$i18n.te(`nofaction`);
		return faction ? faction.name : `nofaction`;
	},
	getParliamentarianDetailed: (state) => (parliamentarianId) => {
		return state.parliamentariansDetails[parliamentarianId];
	},
	getParliamentarianDecisions: (state) => (parliamentarianId) => {
		return state.parliamentariansDecisions[parliamentarianId];
	},
};

export const actions = {
	async fetchParliamentarians({ state, commit }) {
		const fields = [
			"FirstName",
			"LastName",
			"Party",
			"Canton",
			"Image",
			"Faction",
			// "Gender",
			// "DateOfBirth"
		];
		let requestString = `/api/v1/parliamentarian?ps=300&os=0&lp=${state.lp}&lang=${this.$i18n.locale}`;
		fields.forEach((field) => {
			requestString += `&fields=${field}`;
		});
		const res = await this.$axios.$get(requestString);
		commit(
			"SET_PARLIAMENTARIANS",
			res.entities
				.map((entity) => {
					let [id, parliamentarian] = entity;
					return {
						id,
						...parliamentarian,
					};
				})
				.sort((a, b) => {
					let aName = `${a.lastName}${a.firstName}`.toLowerCase();
					let bName = `${b.lastName}${b.firstName}`.toLowerCase();
					return !state.orderByDesc.parliamentarians
						? bName.localeCompare(aName)
						: aName.localeCompare(bName);
				})
		);
	},
	async fetchParliamentarianDetails({ state, commit }, parliamentarianId) {
		if (!state.parliamentariansDetails[parliamentarianId]) {
			const person = await this.$axios.$get(
				`/api/v1/parliamentarian/${parliamentarianId}?lang=${this.$i18n.locale}`
			);
			const rankings = await this.$axios
				.get(`/api/v1/ranking/-/byParliamentarian/${parliamentarianId}?lp=${state.lp}`)
				.then((res) => {
					const ranking = [];
					for (const [key, value] of Object.entries(res.data.ranking)) {
						ranking.push({
							organisation: key,
							...value,
							percentage: value.participationCount
								? Math.round(
										((value.score / value.participationCount) * 100 + Number.EPSILON) * 10000
								  ) / 10000
								: 0,
						});
					}
					return {
						participated: res.data.participated,
						ranking: ranking.sort(
							(a, b) => b.percentage - a.percentage || a.participationCount - b.participationCount
						),
					};
				})
				.catch((err) => {
					throw {
						statusCode: 404,
						message: `Rankings not found for "${parliamentarianId}" – ${err}`,
					};
				});
			commit("ADD_PARLIAMENTARIAN_DETAILED", {
				parliamentarianId,
				parliamentarianDetails: {
					...person,
					...rankings,
				},
			});
		}
	},
	async fetchParliamentarianDecisions({ state, commit }, parliamentarianId) {
		if (!state.parliamentariansDecisions[parliamentarianId]) {
			const parliamentarianDecisions = await this.$axios
				.get(`/api/v1/parliamentarian/${parliamentarianId}/decisions?lp=${state.lp}&lang=${this.$i18n.locale}`)
				.then((res) => {
					const decisions = {};
					for (const entry of res.data) {
						const [vote, decision] = entry;
						decisions[vote] = decision;
					}
					return decisions;
				})
				.catch((err) => {
					throw {
						statusCode: 404,
						message: `Decisions not found for "${parliamentarianId}" – ${err}`,
					};
				});
			commit("ADD_PARLIAMENTARIAN_DECISIONS", {
				parliamentarianId,
				parliamentarianDecisions,
			});
		}
	},
	async fetchParties({ state, commit }) {
		const res = await this.$axios.$get(`/api/v1/party?lang=${this.$i18n.locale}&lp=${state.lp}`);
		const parties = res.entities
			.map((e) => e[1])
			.filter((party) => state.parliamentarians.find((p) => p.party === party.id) || party.id === 1586);
		// TODO: Remove 'demo-day' bugfix in the filter ` || party.id === 1586` after server#100 has been solved
		commit("SET_PARTIES", parties);
	},
	async fetchFactions({ state, commit }) {
		const res = await this.$axios.$get(`/api/v1/faction?lang=${this.$i18n.locale}&lp=${state.lp}`);
		const factions = res.entities
			.map((e) => {
				let [id, data] = e;
				return {
					id,
					...data,
				};
			})
			.filter((faction) => state.parliamentarians.find((p) => p.faction === faction.id));
		commit("SET_FACTIONS", factions);
	},
	async init({ dispatch, commit, state }) {
		if (!state.init) {
			await dispatch("fetchParliamentarians");
			await dispatch("fetchParties");
			await dispatch("fetchFactions");
			await dispatch("rankings/fetchLpVotesCount");
			commit("SET_INIT", true);
		}
	},
	async changeLp({ commit, dispatch }, lp) {
		commit("SET_LP", lp);
		commit("SET_INIT", false);
		commit("rankings/RESET_LP_VOTES_COUNT");
		commit("rankings/RESET_RANKINGS");
		commit("preferences/RESET_PREFERENCES");
		await dispatch("init");
	},
	async nuxtServerInit({ dispatch }) {
		// console.log('init');
		// dispatch('init');
	},

	async nuxtClientInit({ commit, dispatch }) {
		dispatch(`auth/checkLogin`);
	},
};
