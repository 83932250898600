// Custom plugin to wait with translation for page transition
export default ({ app }) => {
	// onBeforeLanguageSwitch called right before setting a new locale
	app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
		// console.log(oldLocale, newLocale, isInitialSetup)
	};
	// onLanguageSwitched called right after a new locale has been set
	app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
		app.store.commit("SET_INIT", false);
		// console.log(oldLocale, newLocale)
	};

	// app.nuxt.defaultTransition.beforeEnter = () => {
	// 	app.i18n.finalizePendingLocaleChange();
	// };
	// Optional: wait for locale before scrolling for a smoother transition
	app.router.options.scrollBehavior = async (to, from, savedPosition) => {
		// Make sure the route has changed
		if (to.name !== from.name) {
			await app.i18n.waitForPendingLocaleChange();
		}
		return savedPosition || { x: 0, y: 0 };
	};
};
