export const state = () => ({
	fields: [
		{
			label: "Search",
			name: "qs",
		},
	],
	lastSearch: null,
	results: null,
	message: ``,
});

export const mutations = {
	setLastSearch(state, searchQuery) {
		state.lastSearch = searchQuery;
	},
	resetLastSearch(state) {
		state.lastSearch = null;
		state.message = ``;
	},
	setResults(state, searchResults) {
		state.results = searchResults;
	},
	resetResults(state) {
		state.results = null;
	},
	setMessage(state, message) {
		state.message = message;
	},
};

export const actions = {
	async search({ commit, state }, models) {
		commit("setMessage", ``);
		if (models.find((e) => e !== "")) {
			let queryString = "";
			state.fields.forEach((field, index) => {
				if (models[index].length >= 4) queryString += `&${field.name}=${encodeURIComponent(models[index])}`;
			});
			if (queryString !== "") {
				commit("setLastSearch", [...models]);
				let offset = 0;
				let positions = 100;
				let res = await this.$axios.$get(
					`/api/v1/search?os=${offset}&ps=${positions}&lang=${this.$i18n.locale}${queryString}`
				);
				if (res.entities && res.entities.length > 0) {
					commit(
						"setResults",
						res.entities
							.map((e) => {
								const [path, data] = e;
								const type = path.substring(8, path.indexOf("/", 8));
								const id = path.slice(path.indexOf(type) + type.length + 1);
								return {
									id,
									path,
									type,
									...data,
								};
							})
							.filter((item, pos, self) => {
								return self.findIndex((e) => e.path === item.path) === pos;
							})
					);
				} else {
					commit("setMessage", `noResults`);
					commit("setResults", []);
				}
			} else {
				commit("setMessage", `moreCharactersNeeded`);
				commit("setResults", []);
			}
		} else {
			commit("setMessage", `noQuery`);
			commit("setResults", []);
		}
	},
};
