export const state = () => ({
	preferences: {},
	preferencesDetails: {},
	preferenceOptions: ["yes", "no"],
});

export const mutations = {
	ADD_PREFERENCES(state, { organisationId, preferences }) {
		if (!(organisationId in state.preferences)) state.preferences[organisationId] = preferences;
	},
	SET_PREFERENCE(state, { organisationId, voteId, preference }) {
		if (organisationId in state.preferences) {
			state.preferences[organisationId][voteId.toString()] = preference;
		} else {
			state.preferences[organisationId] = { [preference.vote]: preference.preference };
		}
	},
	REMOVE_PREFERENCE(state, { organisationId, voteId }) {
		if (organisationId in state.preferences) {
			if (voteId in state.preferences[organisationId]) {
				// we don't delete the entire preference in the store for two reasons:
				// 1. the application 'remembers' that there was a preference for this vote until a refresh (if deleted by mistake)
				// 2. the DOM would need a forced re-render because the preference object no longer exists but remains computed in the Vote component
				state.preferences[organisationId][voteId.toString()].preference = ``;
			}
		}
	},
	ADD_PREFERENCES_DETAILS(state, { organisationId, preferencesDetails }) {
		if (!(organisationId in state.preferencesDetails))
			state.preferencesDetails[organisationId] = preferencesDetails;
	},
	REMOVE_PREFERENCES_DETAILS(state, organisationId) {
		if (organisationId in state.preferencesDetails) delete state.preferencesDetails[organisationId];
	},
	RESET_PREFERENCES(state) {
		state.preferences = {};
		state.preferencesDetails = {};
	},
};

export const getters = {
	preference: () => (vote, preferenceValue) => {
		return {
			bill: vote.bill,
			business: vote.business,
			preference: preferenceValue,
		};
	},
	getPreferences: (state) => (organisationId) => {
		return state.preferences[organisationId];
	},
	getPreferenceByVoteId: (state) => (organisationId, voteId) => {
		const preference = state.preferences[organisationId][voteId];
		return preference ? preference.preference : ``;
	},
	getPreferencesDetails: (state) => (organisationId) => {
		return state.preferencesDetails[organisationId];
	},
};

export const actions = {
	async fetchPreferences({ state, commit, getters, rootState, rootGetters }, { organisationId }) {
		if (!getters.getPreferences(organisationId)) {
			await this.$axios
				.get(`/api/v1/organisation/${organisationId}/preferences?lp=${rootState.lp}`)
				.then((res) => {
					if (res.status === 200) {
						const preferences = {};
						res.data.forEach((e) => {
							const [id, data] = e;
							preferences[id] = data;
						});
						commit("ADD_PREFERENCES", {
							organisationId,
							preferences,
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	},
	async fetchPreferencesDetails({ state, dispatch, commit, getters }, { organisationId }) {
		if (!getters.getPreferencesDetails(organisationId)) {
			await dispatch("fetchPreferences", { organisationId });
			const preferences = getters.getPreferences(organisationId);
			const detailsIds = {
				business: new Set(),
				bill: new Set(),
				vote: new Set(),
			};
			for (const [voteId, preference] of Object.entries(preferences)) {
				detailsIds.business.add(preference.business);
				if (preference.bill) detailsIds.bill.add(preference.bill);
				detailsIds.vote.add(parseInt(voteId));
			}

			const details = {};
			for (const property in detailsIds) {
				const propertyIdsString = Array.from(detailsIds[property]).toString();
				details[property] = await this.$axios
					.get(`/api/v1/${property}/-/byIds?lang=${this.$i18n.locale}&ids=${propertyIdsString}`)
					.then((res) => {
						if (res.status === 200) {
							return res.data.map((e) => {
								let [id, data] = e;
								return {
									id,
									...data,
								};
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}

			const preferencesDetails = [];
			for (const vote of details.vote) {
				const businessIndex = preferencesDetails.findIndex((business) => business.id === vote.business);
				if (businessIndex !== -1) {
					const billIndex = preferencesDetails[businessIndex].bills.findIndex(
						(bill) => bill.id === vote.bill
					);
					if (billIndex !== -1) {
						preferencesDetails[businessIndex].bills[billIndex].votes.push(vote);
					} else {
						preferencesDetails[businessIndex].bills = [
							{
								...details.bill.find((bill) => bill.id === vote.bill),
								votes: [vote],
							},
						];
					}
				} else {
					preferencesDetails.push({
						...details.business.find((business) => business.id === vote.business),
						bills: [
							{
								...details.bill.find((bill) => bill.id === vote.bill),
								votes: [vote],
							},
						],
					});
				}
			}

			if (preferencesDetails.length > 0) {
				commit("ADD_PREFERENCES_DETAILS", {
					organisationId,
					preferencesDetails,
				});
			} else {
				console.log("fetchPreferencesDetails() failed or there are no preferences");
			}
		}
	},
	async updatePreference({ state, commit, getters, dispatch, rootState }, { organisationId, vote, rateValue }) {
		await this.$axios
			.put(`/api/v1/organisation/${organisationId}/preferences/${vote.id}`, `"${rateValue}"`)
			.then(async (res) => {
				if (res.status === 200) {
					commit("SET_PREFERENCE", {
						organisationId,
						voteId: vote.id,
						preference: getters.preference(vote, rateValue),
					});
					commit("REMOVE_PREFERENCES_DETAILS", organisationId);
					commit("rankings/REMOVE_RANKING", organisationId, { root: true });
					commit("RESET_PARLIAMENTARIANS_DETAILED", {}, { root: true });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	},
	async deletePreference({ state, commit, dispatch, rootState }, { organisationId, vote }) {
		await this.$axios
			.delete(`/api/v1/organisation/${organisationId}/preferences/${vote.id}`)
			.then((res) => {
				if (res.status === 200) {
					commit("REMOVE_PREFERENCE", {
						organisationId,
						voteId: vote.id,
					});
					commit("REMOVE_PREFERENCES_DETAILS", organisationId);
					commit("rankings/REMOVE_RANKING", organisationId, { root: true });
					commit("RESET_PARLIAMENTARIANS_DETAILED", {}, { root: true });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	},
};
