export const state = () => ({
	organisations: [],
});

export const mutations = {
	SET_ORGANISATIONS(state, res) {
		state.organisations = res;
	},
	RESET_ORGANISATIONS(state, res) {
		state.organisations = [];
	},
};

export const getters = {
	getOrganisationById: (state) => (res) => {
		return state.organisations.find((org) => org.id === res);
	},
	getOrganisationBySlug: (state) => (res) => {
		return state.organisations.find((org) => org.slug === res);
	},
};

export const actions = {
	async fetchOrganisations({ state, commit }) {
		/* The length of the state.organisations array can only be one of the following scenarios:
		 *  0 = no individual organisation or the complete list of organisations have been fetched yet
		 *  1 = one individual organisation was fetched by a direct link and by calling the "fetchOrganisation" action
		 * >1 = the entire list has already been called by this "fetchOrganisations" action
		 */
		if (state.organisations.length <= 2) {
			const fields = ["Name", "Slug", "Picture", "About", "CreatedAt"];
			let requestString = `/api/v1/organisation?ps=0&os=0`;
			fields.forEach((field) => {
				requestString += `&fields=${field}`;
			});
			const res = await this.$axios.$get(requestString);
			commit(
				"SET_ORGANISATIONS",
				res.entities
					.map((e) => {
						let [id, org] = e;
						return {
							id,
							...org,
						};
					})
					.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
			);
		}
	},
	async fetchOrganisation({ state, commit, getters }, slug) {
		if (!getters.getOrganisationBySlug(slug)) {
			await this.$axios
				.get(`api/v1/organisation/-/bySlug/${slug}`)
				.then((res) => {
					if (res.status === 200) {
						const [id, data] = res.data;
						commit("SET_ORGANISATIONS", [
							{
								id,
								...data,
							},
						]);
					} else {
						console.log("organisation-slug not found");
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	},
};
