export default async function ({ store, redirect, app }) {
	if (app.$cookies.get(store.state.auth.cookieName) && !store.state.auth.loggedIn)
		await store.dispatch("auth/checkLogin");
	if (store.state.auth.loggedIn && store.state.auth.user) {
		// go ahead
	} else {
		// show error message
		// redirect
		redirect(app.localePath({ path: `/login` }));
	}
}
