export const state = () => ({
	ranking: {
		filters: {
			canton: [],
			party: [],
			faction: [],
		},
		searchQuery: "",
	},
	parliamentarians: {
		filters: {
			canton: [],
			party: [],
			faction: [],
		},
		searchQuery: "",
	},
	organisations: {
		searchQuery: "",
	},
});

export const mutations = {
	TOGGLE_FILTER(state, { view, type, element }) {
		const index = state[view].filters[type].findIndex((e) => e === element);
		if (index === -1) {
			state[view].filters[type].push(element);
		} else {
			state[view].filters[type].splice(index, 1);
		}
	},
	SET_SEARCH_QUERY(state, { view, res }) {
		state[view].searchQuery = res;
	},
	RESET(state, view) {
		for (const property in state[view].filters) {
			state[view].filters[property] = [];
			state[view].searchQuery = "";
		}
	},
};

export const getters = {
	showEntry: (state) => (view, entry) => {
		for (const [key, value] of Object.entries(state[view].filters)) {
			if (!(value.length === 0 || value.find((e) => e === entry[key])))
				return false;
		}
		if (state[view].searchQuery !== "") {
			let entryString = "";
			for (const [key, value] of Object.entries(entry)) {
				entryString += value;
			}
			return entryString
				.toLowerCase()
				.includes(state[view].searchQuery.toLowerCase());
		} else {
			return true;
		}
	},
	showReset: (state) => (view) => {
		for (const [key, value] of Object.entries(state[view].filters)) {
			if (value.length !== 0) return true;
		}
		return state[view].searchQuery !== "";
	},
};

export const actions = {};
