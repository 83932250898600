import { render, staticRenderFns } from "./Options.vue?vue&type=template&id=7aea7249&scoped=true&"
import script from "./Options.vue?vue&type=script&lang=js&"
export * from "./Options.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aea7249",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelector: require('/vercel/path0/components/nav/LanguageSelector.vue').default,ColorModeSwitcher: require('/vercel/path0/components/nav/ColorModeSwitcher.vue').default})
