export const state = () => ({
	content: {},
});

export const mutations = {
	ADD_CONTENT(state, { path, content }) {
		state.content[path] = content;
	},
};

export const getters = {
	getContent: (state) => (path) => {
		return state.content[path];
	},
	parseContent: () => (data) => {
		// https://github.com/nuxt/content/blob/main/packages/content/parsers/markdown/index.js
		// https://github.com/nuxt/content/blob/main/packages/content/parsers/markdown/compilers/json.js
		return data;
	},
	getKeyPath: () => (keys) => {
		return `${keys.join(`%2F`)}%2F`;
	},
};

export const actions = {
	async fetchContentByPath({ dispatch, getters }, path) {
		if (!getters.getContent(path)) {
			const pathArray = path
				.slice(4) // remove locale
				.split("/") // split string into array using '/' as pattern
				.filter((e) => e); // remove empty strings from array i.e. a trailing slash
			const pathKeyArray = [];
			for (const element of pathArray) {
				const keyElement = Object.keys(this.$i18n.messages[this.$i18n.locale]).find(
					(key) =>
						this.$i18n.messages[this.$i18n.locale][key].toLowerCase().replace(/\s+/g, "-") ===
						element.toLowerCase()
				);
				if (!keyElement)
					throw {
						statusCode: 404,
						message: `Page not found with key "${element}"`,
					};
				pathKeyArray.push(keyElement);
			}
			await dispatch("fetchContent", { path, reqPath: getters.getKeyPath(pathKeyArray) });
		}
	},
	async fetchContentByKeys({ dispatch, getters }, { path, keys = [] }) {
		if (!getters.getContent(path)) {
			const reqPath = keys.length > 0 ? getters.getKeyPath(keys) : ``;
			await dispatch("fetchContent", { path, reqPath });
		}
	},
	async fetchContent({ state, commit, getters }, { path, reqPath = "" }) {
		await this.$axios
			.get(`/pages%2F${reqPath}${this.$i18n.locale}.md/raw`, this.app.context.env.content.config)
			.then((res) => {
				commit("ADD_CONTENT", {
					path,
					content: getters.parseContent(res.data),
				});
			})
			.catch((err) => {
				throw {
					statusCode: 404,
					message: `Page not found. Error: ${err}`,
				};
			});
	},
};
