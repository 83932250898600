import MarkdownIt from 'markdown-it'

const handlePlugin = (plugin) => plugin.default || plugin

export default ({}, inject) => {
  const md = new MarkdownIt('default', {"linkify":false,"breaks":true,"html":true,"xhtmlOut":true,"typographer":true,"quotes":["«","»","‹","›"]})

  md.use(handlePlugin(require('markdown-it-texmath')))

  inject('md', md)
}