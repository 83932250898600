//
//
//
//
//
//
//
//
//
//

import Footer from "~/components/nav/Footer";

export default {
	components: {
		Footer,
	},
	head() {
		return this.$nuxtI18nHead({ addSeoAttributes: true });
	},
};
