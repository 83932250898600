//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "page-nav",
	data() {
		return {
			menu: ["organisations", "parliamentarian", "mission", "method"],
		};
	},
};
