export const state = () => ({
	loggedIn: false,
	user: null,
	errorMessage: "",
	cookieName: "vlBearer",
	cookieExpDays: 7,
	checkedCookie: false,
});

export const mutations = {
	SET_LOGGED_IN(state, res) {
		state.loggedIn = res;
	},
	SET_USER(state, res) {
		state.user = res;
	},
	SET_CHECKED_COOKIE(state, res) {
		state.checkedCookie = res;
	},
	RESET_AUTH(state) {
		state.loggedIn = false;
		state.user = null;
	},
	SET_ERROR_MESSAGE(state, res) {
		state.errorMessage = res;
	},
};

export const getters = {
	isAdmin: (state) => {
		return !!state.user.admin;
	},
};

export const actions = {
	async login({ state, commit, dispatch }, loginForm) {
		if (loginForm.username && loginForm.password) {
			commit("SET_ERROR_MESSAGE", "");

			await this.$axios
				.post(
					`https://api.votelog.ch/api/v1/session`,
					{},
					{
						auth: { ...loginForm },
					}
				)
				.then(async (res) => {
					await dispatch(`setupUser`, res);
					if (state.loggedIn && state.user) {
						await this.$router.push(
							this.localePath({
								name: `account`,
							})
						);
					} else {
						commit(`SET_ERROR_MESSAGE`, `something went wrong. check console... and you're doomed`);
					}
				})
				.catch((err) => {
					commit(`SET_ERROR_MESSAGE`, `wrong credentials`);
					console.log(err);
				});
		}
	},
	async setupUser({ state, commit }, res) {
		if (res.status === 200) {
			const [data, token] = res.data;
			const orgUpdateCapability = data.permissions.find((e) => "Update" in e.capability);
			if (orgUpdateCapability) {
				const orgPath = orgUpdateCapability.component;
				data.orgId = orgPath.slice(orgPath.lastIndexOf("/") + 1);
				data.org = await this.$axios.$get(`/api/v1/organisation/${data.orgId}`);
				if (data.org) {
					this.$cookies.set(state.cookieName, token, {
						path: "/",
						sameSite: "strict",
						maxAge: 60 * 60 * 24 * state.cookieExpDays,
						secure: true,
					});
					this.$axios.setToken(token, "Bearer");
					commit("SET_USER", data);
					commit("SET_LOGGED_IN", true);
					commit("SET_CHECKED_COOKIE", true);
					// console.log(`logged in`);
				} else {
					// console.log(`organisation doesn't exist. now panic.`);
				}
			} else {
				// console.log(`user doesn't have permissions`);
			}
		}
	},
	async checkLogin({ state, dispatch }) {
		if (!state.checkedCookie) {
			// console.log("check login");
			const token = this.$cookies.get(state.cookieName);
			if (token) {
				await this.$axios
					.get(`/api/v1/session`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(async (res) => {
						// console.log(res);
						if (res.status === 200) {
							await dispatch(`setupUser`, res);
							// you are still logged in
						}
					})
					.catch((err) => {
						// console.log(err);
						// invalid token
					});
			} else {
				// not logged in
			}
		}
	},
	async logout({ state, commit }) {
		if (state.loggedIn && state.user) {
			this.$axios.setToken(false);
			this.$cookies.remove(state.cookieName);
			commit("RESET_AUTH");
			if (this.$router.currentRoute.name.includes(`account`)) {
				await this.$router.push(this.localePath({ path: `/` }));
			}
			// currently, the "DELETE" on session on the API doesn't do anything, and it doesn't always work as of right now...
			// await this.$axios
			// 	.delete(`/api/v1/session`, {})
			// 	.then(async (res) => {
			// 		if (res.status === 200) {
			// 			this.$axios.setToken(false);
			// 			this.$cookies.remove(state.cookieName);
			// 			commit("RESET_AUTH");
			// 			if (this.$router.currentRoute.name.includes(`account`)) {
			// 				await this.$router.push(this.localePath({ path: `/` }));
			// 			}
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		// console.log(err);
			// 	});
		}
	},
};
