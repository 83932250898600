//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "Footer",
	data() {
		return {
			supporters: [
				{
					name: `Prototype Fund Switzerland`,
					img: `https://opendata.ch/wordpress/files/2021/04/PTF-Logo-169.png`,
					url: `https://prototypefund.opendata.ch/`,
				},
				{
					name: `OpenData.ch`,
					img: `https://prototypefund.opendata.ch/files/2020/02/opendata-logo-schwarz-weiss.jpg`,
					url: `https://opendata.ch/`,
				},
				{
					name: `Stiftung Mercator Schweiz`,
					img: `https://prototypefund.opendata.ch/files/2022/01/Mercator_Logo_RGB.jpg`,
					url: `https://www.stiftung-mercator.ch/`,
				},
				{
					name: `Vercel`,
					img: `/powered-by-vercel.svg`,
					url: `https://vercel.com/?utm_source=VoteLog&utm_campaign=oss`,
				},
			],
			contactEmail: `mail@votelog.ch`,
			gitLab: {
				name: `VoteLog on GitLab`,
				url: `https://gitlab.com/votelog`,
				img: `https://about.gitlab.com/images/press/logo/svg/gitlab-icon-rgb.svg`,
			},
		};
	},
	computed: {
		dateString() {
			let date = new Date();
			return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
		},
	},
};
