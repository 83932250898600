//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			noText: `404: This isn't the site you're looking for…`,
			noImg: `https://media.giphy.com/media/26u7JfneuEJQQ2VClY/giphy.gif`
		}
	}
}
