//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "sidebar",
	data() {
		return {
			open: false,
			metaMenu: ["privacy", "imprint"],
		};
	},
	methods: {
		toggleMenu() {
			this.open = !this.open;
		},
	},
};
